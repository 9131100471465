import React from 'react';
import pab from '../../assets/img/partners/pab.svg';
import avrora from '../../assets/img/partners/avrora.svg';
import ukr from '../../assets/img/partners/ukr.svg';
import avtotrans from '../../assets/img/partners/avtotrans.svg';
import yavir from '../../assets/img/partners/yavir.svg';
import pilot from '../../assets/img/partners/epilot.svg';
import green from '../../assets/img/partners/green_s.svg';
import konsort from '../../assets/img/partners/konsort.svg';
import imig from '../../assets/img/partners/imig.svg';
import torgservis from '../../assets/img/partners/torgservis.svg';
import k from '../../assets/img/partners/k.svg';
import ende from '../../assets/img/partners/endevour.svg';
import him from '../../assets/img/partners/him.svg';
import kolo from '../../assets/img/partners/kolo_news.svg';
import ptv from '../../assets/img/partners/ptv.svg';

const Partners = () => {
    const sponsorsList = [
        [
            {img: pab, name: 'Організатор'},
            {img: avrora, name: 'Учасник ПАБу'},
            {img: ukr, name: 'Учасник ПАБу'},
            {img: avtotrans, name: 'Учасник ПАБу'},
            {img: yavir, name: 'Учасник ПАБу'},
            {img: pilot, name: 'Учасник ПАБу'},
            {img: him, name: 'Учасник ПАБу'},
        ],
        [
            {img: green, name: 'Учасник ПАБу'},
            {img: konsort, name: 'Учасник ПАБу'},
            {img: imig, name: 'Учасник ПАБу'},
            {img: torgservis, name: 'Учасник ПАБу'},
            {img: k, name: 'Учасник ПАБу'},
            {img: ende, name: 'Учасник ПАБу'}
        ],
        [
            {img: kolo, name: 'Інформаційний партнер'},
            {img: ptv, name: 'Інформаційний партнер'},
        ]
    ];

    const sponsorItem = (img, text, cls) => <div key={img + 'fdf'}
                                                 className={cls === 'y' ? 'sponsors_item sponsors_item--y' : 'sponsors_item'}>
        <div className="sponsors_item--img"><img src={img} className='sponsors_logo' alt="icon: logo-Sponsor/Partner"/>
        </div>
        <strong className="sponsors_item--text">{text}</strong>
    </div>;

    return (
        <div id='partners' className="sponsors">
            <h1 className="sponsors_header">Партнери</h1>
            <div className="container">
                <div className="sponsors_wrapper">
                    <div className="sponsors_list">
                        {sponsorsList.map((list) => {
                            return <div key={list[0].img}
                                        className="sponsors_list--line">{list.map((item) => sponsorItem(item.img, item.name, item.cls))}</div>;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partners;