import React, {useState} from 'react';
import Teacher_img_1 from '../../assets/img/teachers/teacher_img_1.png';
import Teacher_img_2 from '../../assets/img/teachers/teacher_img_2.png';
import Teacher_img_3 from '../../assets/img/teachers/piven_anna.png';
import Teacher_img_4 from '../../assets/img/teachers/panchencko.png';
import Teacher_img_5 from '../../assets/img/teachers/zoss-kior.png';
import Teacher_img_6 from '../../assets/img/teachers/lytovchenko.png';
import Teacher_img_7 from '../../assets/img/teachers/svitlana_rak.png';

const Teacher = () => {
  const arrTeacher = [
    {
      name: 'Сергій Володимирович Степаненко',
      img: Teacher_img_1,
      smile: 'smileOrange',
      official: 'Кандидат економічних наук, доцент, завідувач кафедри політекономії ПНПУ імені Короленка, експерт з акредитації освітніх програм, фасилітатор',
      noOfficial: 'Гуру з економіки, найкращий гітарист (за версією наших випускників) та викладач наших курсів від старту PRO:освіти 2019 року. ',
      quotation: '«Подорож у тисячу миль починається з першого кроку»'
    },
    {
      name: 'Дорофєєв Олександр Вікторович',
      smile: 'jetIcon',
      img: Teacher_img_2,
      official: 'Доктор економічних наук, професор, професор кафедри публічного управління та адміністрування Полтавського державного аграрного університету',
      noOfficial: 'Консультант з менеджменту. Мандрівник, який знайшов гармонію і колекціонує позитивні емоції та приємні спогади.',
      quotation: '«Жити треба з задоволенням»'
    },
    {
      name: 'Анна Півень',
      smile: 'smileBlue',
      img: Teacher_img_3,
      noOfficial: 'Тренерка з розвитку soft skills; фанатка якісної профорієнтації (може готувати масштабний фестиваль на кілька годин з підготовкою в 2 місяці). Часом вірить у людей більше, ніж вони в себе',
      official: 'Кар\'єрна консультантка, НR-менджерка, проєктна менеджерка',
      quotation: '«Немає професій назавжди»'
    },
    {
      name: 'Наталія Панченко',
      smile: 'jetIcon',
      img: Teacher_img_4,
      noOfficial: 'Розповідає людям складні істини простими словами, а ще рятує стосунки та кар\'єри людей (за їхніми ж словами)',
      official: 'Заступниця директора Обласного молодіжного центру, психологиня муніципального проєкту «ТатоHub Полтава» та СпівДія',
      quotation: '«Ніколи не здавайся, ганьбися до кінця 🤷🏻‍♀️😅»'
    },
    {
      name: 'Микола Зось-Кіор ',
      smile:'smileBlue',
      img: Teacher_img_5,
      official: 'Доктор економічних наук, професор кафедри менеджменту ім. І. А. Маркіної Полтавського державного аграрного університету.',
      noOfficial: 'Пошуковець лайфхаків',
      quotation: '«Звичайні люди одержимі розвагами. Лідери одержимі освітою»'
    },
    {
      name: 'Андріан Литовченко',
      smile: 'smileOrange',
      img: Teacher_img_6,
      official: 'Маркетолог із вищою освітою, директор Полтавського автоагрегатного заводу. 20 років на ПААЗі – від маркетолога до керівника заводу. Ironman. Засновник бігового клубу.',
      noOfficial: 'Марафонець. Підпрацьовую з ранку будильником. (Хоча це побічний ефект). Щаслива людина. Люблю інших людей',
      quotation: '«Краще зробити і пошкодувати, ніж шкодувати, що не зробив»'
    },
    {
      name: 'Світлана Рак',
      smile: 'jetIcon',
      img: Teacher_img_7,
      official: 'Тренерка з дизайну голосу та публічних виступів, підприємиця, спікерка, кандидат юридичних наук.',
      noOfficial: 'Маю експертний блог в Інстаграм, бігаю ранками і не люблю готувати',
      quotation: '«Один публічний виступ може змінити ваше життя»'
    }
  ];

  const TeacherCard = ({item}) => {
    const [hover, setHover] = useState(false);
    return (
      <div className="teacher_card" onMouseEnter={() => {
        setHover(true);
      }} onMouseLeave={() => {
        setHover(false);
      }}>
        <img className="teacher_card--img" src={item.img} alt={'img: Teacher'}/>
        {!hover ? <div className="teacher_card--content"><p className='teacher_card--name'>{item.name}</p>
          <strong className='teacher_card--title'>Неофіційно:</strong>
          <p className='teacher_card--text'>{item.noOfficial}</p></div>: (<div className="teacher_info">
          <h2 className={`teacher_card--name teacher_card--name-purple teacher_card--name-${item.smile}`}>{item.name}</h2>
          <h3 className="teacher_info--header">Офіційно:</h3>
          <p className="teacher_info--text">{item.official}</p>
          <h3 className="teacher_info--header">Неофіційно:</h3>
          <p className="teacher_info--text">{item.noOfficial}</p>
          <h3 className="teacher_info--header">Життєве гасло:</h3>
          <p className="teacher_info--text">{item.quotation}</p>
        </div>)}

      </div>
    );
  };

  return (
    <div className='teachers'>
      < h3 className="teachers-header" id="teacher">2019-2023<br/>
        Викладачі та тренери
      < /h3>
      <div className="container">
        <div className="teacher">
          {arrTeacher.map(item => <TeacherCard key={item.name} item={item}/>)}
        </div>
      </div>
    </div>
  );
};

export default Teacher;