import React from 'react';
import Button from "../../UI/Button/Button";

const Menu = ({tabs}) => {
    return (
        <div className='menu'>
            <div className='container'>
                <ul className='menu_wrapper'>

                    {tabs.map((item) => (
                        <li className="menu_item menu_item--menu" key={item.id}>
                            <a className="navbar_link" href={item.link}>{item.name}</a>
                        </li>
                    ))}
                    <li className="menu_item">
                        <p className='menu_item--button'><Button size="big"/></p>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Menu;