import React, {createContext, useEffect, useState} from 'react';
import Navbar from './component/Navbar';
import firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import Footer from "./component/Footer";
import {BrowserRouter, useHistory} from "react-router-dom";
import Routes from "./routes";
import {getDB} from "./api";
import Beginner from "./UI/ModalInfo/Beginner";

firebase.initializeApp({
    apiKey: 'AIzaSyDt76JiuHuQ_d0MforYcSLE3O1nVgdNNmU',
    authDomain: 'pro-osvita.firebaseapp.com',
    projectId: 'pro-osvita',
    storageBucket: 'pro-osvita.appspot.com',
    messagingSenderId: '955057243689',
    appId: '1:955057243689:web:b4760cb2ea1873c4c7be38'
});

const auth = firebase.auth();
const firestore = firebase.firestore;
export const FirebaseContext = createContext({firebase, firestore, auth, user: null, isAdmin: false});

function App() {
    const [user] = useAuthState(auth);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (!user) return;
        try {
            (async () => {
                const res = await getDB("admins");
                res.forEach((adminData) =>
                    adminData.data()?.uid === user.uid
                        ? setIsAdmin(true)
                        : setIsAdmin(false)
                );
            })();
        } catch (e) {
            console.log(e);
        }
    }, [isAdmin, user]);

    return (
        <div className="App">
            <BrowserRouter>
                <Navbar/>
                <FirebaseContext.Provider value={{firebase, firestore, auth, isAdmin, user}}>
                    <Routes/>
                </FirebaseContext.Provider>
                <Footer/>
            </BrowserRouter>
        </div>
    );
}

export default App;
