import React from 'react';
import style from './Modal.module.sass';
import done from '../../assets/img/done_green.svg'
import Button from "../Button/Button";
import Bag from '../../assets/img/bag.svg';
import Jet from '../../assets/img/icon_jet.svg';
// import Close from '../../assets/img/'

const Beginner = ({closeFunc}) => {
    return (
        <div className={style.modalWrapper} onClick={()=>closeFunc(false)}>
            <div className={style.modal} onClick={(e)=>e.stopPropagation()}>
                <div className={`${style.head} ${style.headGreen}`}>
                    <h3 className={style.header}>BEGINNER</h3>
                    <button className={style.close} onClick={()=>closeFunc(false)}>
                        <p>+</p>
                    </button>
                </div>
                <div className={style.content}>
                    <div className={style.df}>
                        <div className={style.bannerWrapper}>
                            <div className={style.banner}>
                                <p className={style.bannerText}>Welcome!</p>
                            </div>
                        </div>
                        <div>
                            <br/>
                            <p className={style.text}>
                                Вперше чуєш про наш проєкт? <br/>
                                Або хочеш просто відвідати 1 заняття на вибір?
                            </p>
                            <br/>
                            <p className={style.text}>
                                Цей варіант – для тебе. Обирай зручний формат:
                                онлайн або офлайн. Заняття будуть проходити
                                приблизно 1 раз на тиждень. Орієнтовно в другій
                                половині дня щочетверга або щоп'ятниці. Після
                                реєстрації ти отримаєш доступ до календаря проєкту.
                            </p>
                            <br/>
                        </div>
                        <div className={style.infoWrapper}>
                            <img className={style.infoImgF} src={Bag} alt='img: bag'/>
                            <h4 className={style.infoHeader}>У програмі:</h4>
                            <div className={style.info}>
                                <ul className={style.infoList}>
                                    <li className={style.infoItem}>
                                        <img className={style.infoMarker} src={done} alt="icon: Done"/>
                                        <p className={style.text}><strong
                                            className={style.textStrong}>Онлайн-заняття</strong></p>
                                    </li>
                                    <li className={style.infoItem}>
                                        <img className={style.infoMarker} src={done} alt="icon: Done"/>
                                        <p className={style.text}><strong className={style.textStrong}>Екскурсії на
                                            підприємства Полтави*</strong></p>
                                    </li>
                                    <li className={style.infoItem}>
                                        <img className={style.infoMarker} src={done} alt="icon: Done"/>
                                        <p className={style.text}><strong
                                            className={style.textStrong}>Офлайн-тренінги</strong> по 2 години**</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={style.main}>
                        <p className={`${style.text} ${style.mb6}`}>
                            Графік буде гнучким, щоб ми з тобою могли швидко реагувати на всі зміни воєнної ситуації.
                        </p>
                        <p className={`${style.text} ${style.mb6}`}>
                            Програма – легка для вивчення. Наші викладачі розповідають про різні теми з бізнесу PROстими
                            словами.
                            А ще в нас лише PROгресивні тренери. Тому буде дружня атмосфера і цікаве спілкування.
                            А ще – різні вправи і для команди, і для того, щоб проявити себе.
                        </p>
                        <p className={`${style.text} ${style.mb6}`}>
                            Детальну програму ти дізнаєшся після старту проєкту. Серед тем чекай на заняття з курсів
                            PRO:економіку,
                            PRO:маркетинг та PRO:менеджмент. І тренінги на розвиток soft skills. З нашими тренерами ти
                            PROкачаєш
                            вміння самопрезентації, креативності, роботи зі своїми емоціями та планування. Даємо тобі
                            знання,
                            що допоможуть визначитися з майбутньою професією та досягти успіху в житті.
                        </p>
                        <p className={`${style.text} ${style.mb6}`}>
                            З тобою на зв'язку <strong className={style.textStrong}>професійні психолог та
                            координатор</strong>. З ними можна поговорити про свої почуття
                            і трошки зняти напругу.
                        </p>
                        <p className={`${style.text} ${style.mb13}`}>
                            Витрати проєкту бере на себе Полтавська асоціація бізнесу.<br/>
                            Твій організаційний внесок <strong className={style.textStrong}>150 грн</strong>. Тож
                            обговори це питання з батьками.
                        </p>
                    </div>
                    <p className={`${style.text} ${style.mb13}`}>
                        Чого чекаєш? Реєструйся швидше на новий набір курсу 2023 року
                    </p>
                    <img className={style.img} src={Jet} alt="img: jet"/>
                    <Button/>
                    <p className={style.subtext}>
                        * Для відвідування необхідна присутність у Полтаві
                        ** Кількість офлайн зустрічей залежатиме від воєнної ситуації
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Beginner;