import React, {useEffect, useState} from 'react';
import FinishForm from "./FinishForm";
import {setDB} from "../../api";

const Form = () => {
    const [data, setData] = useState({
        name: '',
        email: '',
        phone: '',
        telegram: '',
        study: '',
        year: '',
        allowPD: false,
        course: 'FULL – повний курс',
        day: 'четвер',
        phone2: '',
        allowParents: false,
        text: '',
    });
    const [errorFirst, setErrorFirst] = useState(false);
    const [errorSec, setErrorSec] = useState(false);
    const [close, setClose] = useState(false);
    const [nextStep, setNextStep] = useState(false);

    const setValue = (value, input) => {
        setData({...data, [input]: value});
    };
    const validationFirst = () => {
        let flag = true;
        const arr = ['name', 'email', 'phone', 'study', 'year', 'allowPD'];
        arr.forEach(item => {
            if (!!data[item] === false) {
                flag = false;
                setErrorFirst(true);
            }
        })
        if (flag) {
            setErrorFirst(false);
            setNextStep(true);
        }
    }
    const validationSec = () => {
        let flag = true;
        const arr = ['course', 'day', 'phone2'];
        arr.forEach(item => {
            if (!!data[item] === false) {
                flag = false;
                setErrorSec(true);
            }
        })
        if (flag) {
            setErrorSec(false);
            console.log(data);
            setDB({...data, checked: false, finished: false, date: Date.now()});
            setClose(true);
        }
    }

    const arrOption = ['FULL – повний курс', 'LIGHT – онлайн-участь', 'BEGINNER – одне заняття на вибір']
    const arrDays = ['четвер', 'п\'ятниця', 'обидва варіанти влаштовують']

    // <FinishForm/>
    return (
        <div className='form'>
            <div className='form_wrapper'>
                {close ?
                    <FinishForm/> :
                    nextStep ?
                        <>
                            <h1 className='form_header'>Peєстрація</h1>
                            <form className="form_container">
                                {errorSec ?
                                    <div className="form_error">Всі ОБОВ'ЯЗКОВІ поля мають бути заповнені</div> : ''}
                                <label className="form_label">
                                    Який формат програми тебе цікавить?
                                    <select className="form_input" value={data.course} onChange={(e) => {
                                        setValue(e.target.value, 'course')
                                    }}>
                                        {arrOption.map((item) => <option className='form_option'
                                                                         key={item}>{item}</option>)}
                                    </select>
                                </label>
                                <label className="form_label">
                                    Який день тижня для тебе більш зручний для відвідування занять?
                                    <select className="form_input" value={data.day} onChange={(e) => {
                                        setValue(e.target.value, 'day')
                                    }}>
                                        {arrDays.map((item) => <option className='form_option'
                                                                       key={item}>{item}</option>)}
                                    </select>
                                </label>
                                <label className="form_label">
                                    Вкажи номер телефону когось із відповідальних за тебе дорослих разом із ім'ям
                                    <input value={data.phone2} className="form_input"
                                           onChange={(e) => setValue(e.target.value, 'phone2')}/>
                                    <p className="form_text">(Наприклад, 099 99 999 99 Світлана, мама)<br/>
                                        Ми сконтактуємо за цим номером після твоєї реєстрації, щоб домовитися про сплату
                                        організаційного внеску.
                                    </p>
                                </label>
                                <label className="form_label">
                                    Які питання до організаторів у тебе залишилися?&nbsp;*
                                    <input value={data.text} className="form_input"
                                           onChange={(e) => setValue(e.target.value, 'text')}/>
                                </label>
                                <label className="form_label-checkbox" htmlFor='allowParents'>
                                    <input id='allowParents' checked={data.allowParents} type='checkbox'
                                           className="form_inputCheckbox"
                                           onChange={(e) => setValue(!data.allowParents, 'allowParents')}/>
                                    <p className="form_textLg">Я попередив/-ла дорослих, що планую участь у
                                        проєкті&nbsp;*</p>
                                </label>

                                <div className='form-df'>
                                    <button className="form_arr" onClick={(e) => {
                                        e.preventDefault();
                                        setNextStep(false);
                                    }}>
                                        &lt; Попередній крок
                                    </button>

                                    <button className="form_button" onClick={(e) => {
                                        e.preventDefault();
                                        validationSec();
                                    }}>Відправити заявку
                                    </button>
                                </div>
                            </form>
                            <p className="form_text mt-20">* - не обов'язкові поля</p>

                        </>
                        : (
                            <>
                                <h1 className='form_header'>Peєстрація</h1>
                                <form className="form_container">
                                    {errorFirst ?
                                        <div className="form_error">Всі ОБОВ'ЯЗКОВІ поля мають бути заповнені</div> : ''}
                                    <label className="form_label">
                                        Твоє ім'я та прізвище
                                        <input value={data.name} className="form_input"
                                               onChange={(e) => setValue(e.target.value, 'name')}/>
                                    </label>
                                    <label className="form_label">
                                        Твоя електронна пошта
                                        <input value={data.email} className="form_input"
                                               onChange={(e) => setValue(e.target.value, 'email')}/>
                                    </label>
                                    <label className="form_label">
                                        Номер телефону
                                        <input value={data.phone} className="form_input"
                                               onChange={(e) => setValue(e.target.value, 'phone')}/>
                                        <p className="form_text">Бажано з прив'язкою до телеграму</p>
                                    </label>
                                    <label className="form_label">
                                        Вкажи свій нікнейм у телеграмі&nbsp;*
                                        <input value={data.telegram} className="form_input"
                                               onChange={(e) => setValue(e.target.value, 'telegram')}/>
                                    </label>
                                    <label className="form_label">
                                        Де ти зараз навчаєшся?
                                        <input value={data.study} className="form_input"
                                               onChange={(e) => setValue(e.target.value, 'study')}/>
                                        <p className="form_text">(Вкажи назву навчального закладу та клас або курс.
                                            Наприклад, школа №1, 11 клас)</p>
                                    </label>
                                    <label className="form_label">
                                        Скільки тобі повних років?
                                        <input value={data.year} className="form_input"
                                               onChange={(e) => setValue(e.target.value, 'year')}/>
                                    </label>
                                    <label className="form_label-checkbox" htmlFor='allow'>
                                        <input id='allow' type='checkbox'
                                               checked={data.allowPD}
                                               className="form_inputCheckbox"
                                               onChange={(e) => setValue(!data.allowPD, 'allowPD')}/>
                                        <p className="form_textLg">Я даю згоду на обробку моїх персональних даних</p>
                                    </label>
                                    <button className="form_arr" onClick={(e) => {
                                        e.preventDefault();
                                        validationFirst();
                                    }}>Наступний крок &gt;
                                    </button>
                                </form>
                                <p className="form_text mt-20">* - не обов'язкові поля</p>
                            </>

                        )}
            </div>
        </div>
    );
};

export default Form;