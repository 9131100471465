import React from 'react';
import Slider from "./Slider";
import Button from "../../UI/Button/Button";
import iconTelegram from "../../assets/img/icon_telegram.svg";

const Study = () => {
    return (
        <div className='study' id='study'>
            <h2 className='study_title title'>Наш проєкт – дружній до ВПО.<br/> Напиши координатору, якщо тебе цікавить безоплатна участь<br/>
                <a className="study_btn-tg" target='_blank' href="https://t.me/pab_poltava"
                   title="координатор">
                    <img src={iconTelegram} alt="logo: Telegram"/>
                </a>
            </h2>
            <div className='container'>
                <div className='study_wrapper'>
                    <div className='study_content'>
                        <div className='study_content--slider slider'>{<Slider/>}</div>
                        <div className="study_content--text textStudy">
                            <p className='textStudy_header'>Може досить просто мріяти про власний успіх?! Час діяти!</p>
                            <p className='textStudy_content'>Ми даємо базу знань, круті знайомства та сучасні навички
                            </p>
                            <p className='textStudy_content'>Псс...до речі, з цими знаннями ти зустрічатимешся у житті щодня. Просто активні люди використовують це на свою користь. І швидко досягають успіху в будь-якій сфері.</p>
                            <p className='textStudy_content'>Обирай, де хочеш бути?</p>
                            <p className='textStudy_header'>Давай навчатися разом!</p>
                        </div>
                    </div>
                    <h4 className='study_subtitle'>PRO:освіта – спільнота прогресивної молоді</h4>
                    <div className='textStudy_btn'><Button size={'Big'}/></div>
                </div>
            </div>
        </div>
    );
};

export default Study;