import React from 'react';
import style from './Modal.module.sass';
import done from '../../assets/img/done_green.svg'
import Button from "../Button/Button";
import Smile from "../../assets/img/smile_blue_2.svg";
import Jet from "../../assets/img/icon_jet.svg";

const Light = ({closeFunc}) => {
    return (
        <div className={style.modalWrapper} onClick={()=>closeFunc(false)}>
            <div className={style.modal} onClick={(e)=>e.stopPropagation()}>
                <div className={`${style.head} ${style.headBlue}`}>
                    <h3 className={style.header}>LIGHT</h3>
                    <button className={style.close} onClick={()=>closeFunc(false)}>
                        <p>+</p>
                    </button>
                </div>
                <div className={style.content}>
                    <div className={`${style.df} ${style.mb20} ${style.mt20}`}>
                        <div>
                            <br/>
                            <p className={style.text}>
                                Шукаєш легкий варіант додаткового дистанційного навчання?
                                Тоді саме для тебе спрощений формат LIGHT
                                з доступом з будь-якої точки світу 🔥
                            </p>
                            <br/>
                            <p className={`${style.text} ${style.mb6}`}>
                                <strong className={style.textStrong}>
                                    Що Буде?
                                </strong>
                            </p>

                            <p className={style.text}>
                                Графік буде гнучким, щоб ми з тобою
                                могли швидко реагувати на всі зміни
                                воєнної ситуації.
                            </p>
                            <br/>
                        </div>
                        <div className={style.infoWrapper}>
                            <img className={style.infoImgS} src={Smile} alt='img: bag'/>
                            <h4 className={style.infoHeader}>У програмі:</h4>
                            <div className={`${style.info} ${style.infoLong}`}>
                                <ul className={style.infoList}>
                                    <li className={style.infoItem}>
                                        <img className={style.infoMarker} src={done} alt="icon: Done"/>
                                        <p className={style.text}><strong
                                            className={style.textStrong}>3 онлайн-заняття</strong> з приблизною
                                            періодичністю
                                            1 раз на 2-3 тижні. Орієнтовно в другій половині дня
                                            щочетверга або щоп'ятниці.</p>
                                    </li>
                                    <li className={style.infoItem}>
                                        <img className={style.infoMarker} src={done} alt="icon: Done"/>
                                        <p className={style.text}>Доступ до відеозаписів у тебе буде впродовж
                                            усього 2023 року.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={style.main}>
                        <p className={`${style.text} ${style.mb6}`}>
                            Програма – легка для вивчення. Наші викладачі розповідають про різні теми з бізнесу PROстими
                            словами.
                            А ще в нас лише PROгресивні тренери. Тому буде дружня атмосфера і цікаве спілкування.
                            А ще – різні вправи і для команди, і для того, щоб проявити себе.
                        </p>
                        <p className={`${style.text} ${style.mb6}`}>
                            Детальну програму ти дізнаєшся після старту проєкту. Серед тем чекай на заняття з курсів
                            PRO:економіку,
                            PRO:маркетинг та PRO:менеджмент. І тренінги на розвиток soft skills. З нашими тренерами ти
                            PROкачаєш
                            вміння самопрезентації, креативності, роботи зі своїми емоціями та планування. Даємо тобі
                            знання,
                            що допоможуть визначитися з майбутньою професією та досягти успіху в житті.
                        </p>
                        <p className={`${style.text} ${style.mb6}`}>
                            З тобою на зв'язку <strong className={style.textStrong}>професійні психолог та
                            координатор</strong>. З ними можна поговорити про свої почуття
                            і трошки зняти напругу.
                        </p>
                        <p className={`${style.text} ${style.mb20}`}>
                            Витрати проєкту бере на себе Полтавська асоціація бізнесу.<br/>
                            Твій організаційний внесок <strong className={style.textStrong}>300 грн</strong>. Тож
                            обговори це питання з батьками.
                        </p>
                    </div>
                    <p className={`${style.text} ${style.mb20}`}>
                        Чого чекаєш? Реєструйся швидше на новий набір курсу 2023 року
                    </p>
                    <img className={style.img} src={Jet} alt="img: jet"/>
                    <Button/>
                </div>
            </div>
        </div>
    );
};

export default Light;