import React, {useEffect, useState} from 'react';
import {getDB, logOut, setDBDoc} from "../api";
import {Redirect} from "react-router-dom";

const AdminPage = () => {
        const [oldData, setOldData] = useState([]);
        const [requestData, setRequestData] = useState([])
        const [filterData, setFilterData] = useState([])
        const [showAll, setShowAll] = useState(false);

        useEffect(async () => {
            try {
                const requests = await getDB('2023');
                let data = [...requests.map((e) => {
                    let data = e.data();
                    let uid = e.f_.path.segments[6];
                    return {...data, uid};
                })];
                setRequestData([...data])
            } catch (e) {
                return <Redirect to={'/'}/>
            }

        }, []);

        useEffect(async () => {
            try {
                const requests = await getDB('requests');
                let data = [...requests.map((e) => {
                    let data = e.data();
                    let uid = e.f_.path.segments[6];
                    return {...data, uid};
                })];
                setOldData([...data])
            } catch (e) {
                return <Redirect to={'/'}/>
            }

        }, []);

        useEffect(() => {
            let arr = []
            if (!requestData.length) {
                return
            }
            arr = requestData.filter(item => item.hasOwnProperty('date'))
            setFilterData([...arr]);
        }, [requestData])

        useEffect(() => {
            if (!filterData.length) {
                return
            }
            filterData.sort(function (a, b) {
                if (a.date > b.date) {
                    return -1;
                }
                if (a.date < b.date) {
                    return 1;
                }
                return 0;
            })
        }, [filterData])

        const onSubmitHandler = async (uid, data) => {
            try {
                await setDBDoc(`${uid}`, data);
            } catch (e) {
                console.log(e);
            }
        }


        function Card({item, check=true}) {
            const [checked, setChecked] = useState(item.checked);

            const handlerChecked = async () => {
                setChecked(!checked);
                try {
                    await onSubmitHandler(item.uid, {...item, checked: !checked})
                } catch (e) {
                    console.log(e)
                }

            }

            return (<div className="adminPageCard">
                <h1 className="adminPage_title">{item.media}</h1>
                <ul className="adminPageCard_list">
                    <li className="adminPageCard_title">
                        <p>{item.name}</p>
                    </li>
                    <li className="adminPageCard_item">
                        <a href={`mailto:${item.email}`}
                           className="adminPageCard_item--link"><strong>E-mail:<br/></strong>{item.email}</a>
                    </li>
                    <li className="adminPageCard_item">
                        <a href={`tel:${item.phone}`}
                           className="adminPageCard_item--link"><strong>Телефон:</strong>{item.phone}
                        </a>
                    </li>
                    {item?.telegram ?
                        <li className="adminPageCard_item">
                            <a href={`tel:${item.telegram}`}
                               className="adminPageCard_item--link"><strong>Telegram:</strong>{item.telegram}
                            </a>
                        </li> :
                        ''
                    }
                    <li className="adminPageCard_item">
                        <p><strong>Навчаюсь в:</strong>{item.study}</p>
                    </li>
                    <li className="adminPageCard_item">
                        <p><strong>Повних років:</strong>{item.year}</p>
                    </li>
                    <li className="adminPageCard_item">
                        <p><strong>Курс:</strong>{item.course}</p>
                    </li>
                    <li className="adminPageCard_item">
                        <p><strong>Зручний день:</strong>
                            {item?.day ? item.day : "відсутня інформація"}</p>
                    </li>
                    <li className="adminPageCard_item">
                        <p><strong>Номер відповідального:</strong> {item?.phone2 ? item.phone2 : "відсутня інформація"}</p>
                    </li>
                    <li className="adminPageCard_item">
                        <p><strong>Батьків
                            повідомив?:</strong>{item?.allowParents ? "так" : "ні"}</p>
                    </li>
                    <li className="adminPageCard_item">
                        <p><strong>Додаткові питання:</strong>{item?.text ? item.text : "-"}</p>
                    </li>
                    <li className="adminPageCard_item">
                        <p><strong>Дата реєстрації:</strong>{item?.date ? new Date(item.date).toLocaleDateString() : '-'}
                        </p>
                    </li>
                    <hr/>
                    {
                        check ?
                            <>
                                <br/>
                                <li><input type='checkbox' checked={checked} onChange={() => handlerChecked()}/> Лист
                                    відправлено
                                </li>
                            </>
                            :
                            ""
                    }
                </ul>
            </div>)
        };

        return (
            <div className='adminPage'>
                <div className="adminPage_wrapper">
                    <div className='adminPage_header'>
                        <h2 className="adminPage_title">Requests</h2>
                        <button className='adminPage_button' onClick={async (e) => {
                            e.preventDefault();
                            try {
                                await logOut();
                                return <Redirect to={'/'}/>
                            } catch (e) {
                                console.log(e);
                            }
                        }}>Log out
                        </button>
                    </div>
                    <div className="adminPage_container">
                        {filterData.map((item) => <Card key={item.uid} item={item}/>)}
                    </div>
                    <button className='adminPage_button mb20' onClick={() => {
                        setShowAll(!showAll)
                    }}>
                        {!showAll ? 'Показати всі записи' : 'Приховати всі записи'}</button>
                    {
                        showAll ?
                            <div className="adminPage_container">
                                {oldData.map((item) => <Card key={item.uid} item={item} check={false}/>)}
                            </div> : ''
                    }
                </div>
            </div>
        );
    }
;

export default AdminPage;