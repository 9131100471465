import React from 'react';
import Header from "../component/Header";
import InfoInNumber from "../component/InfoInNumber";
import ProosvitaInfo from "../component/ProosvitaInfo";
import Study from "../component/Study";
import Course from "../component/Course";
import Learn from "../component/Learn";
import Teacher from "../component/Teacher";
import Partners from "../component/Partners";
import Capabilities from "../component/Capabilities";
import Security from "../component/Security";

const HomePage = () => {
    return (
        <>
            <Header/>
            <InfoInNumber/>
            <ProosvitaInfo/>
            <Capabilities/>
            <Course/>
            <Study/>
            <Security/>
            <Learn/>
            <Teacher/>
            <Partners/>
        </>
    );
};

export default HomePage;