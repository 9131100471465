import React, {Component} from "react";
import Slider from "react-slick";
import slide1 from '../../assets/img/people_1.png'
import slide2 from '../../assets/img/people_2.png'
import slide3 from '../../assets/img/people_3.png'
import slide4 from '../../assets/img/people_4.png'
import slide5 from '../../assets/img/people_5.png'
import slide6 from '../../assets/img/people_6.png'
import slide7 from '../../assets/img/people_7.png'
import slide8 from '../../assets/img/people_8.png'
import slide9 from '../../assets/img/people_9.png'
import slide10 from '../../assets/img/people_10.png'
import slide11 from '../../assets/img/people_11.png'
import slide12 from '../../assets/img/people_12.png'

export default class SlickSlider extends Component {
    render() {
        const settings = {
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover: true,
            centerPadding: '60px',
            responsive: [
                {
                    breakpoint: 700,
                    settings: {
                        autoplay: false
                    }
                }
            ]
        }

        const arrPhoto = [slide1, slide2, slide3, slide4, slide5, slide6, slide7,  slide8, slide9, slide10, slide11, slide12]

        return (
            <Slider {...settings}>
                {arrPhoto.map(item=>(
                    <div key={item} className='slider_item'>
                        <img className='slider_item--img' alt='img: People' src={item}/>
                    </div>
                ))}
            </Slider>
        );
    }
};