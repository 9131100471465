import React from 'react';
import Button from "../../UI/Button/Button";

const InfoInNumber = () => {

    const info = [
        {id: '1', head: '3', text: `роки втілення `,  afterText: ''},
        {id: '2', head: '7', text: 'потоків слухачів ',  afterText: ''},
        {id: '3', head: '10', text: 'спікерів ',  afterText: 'понад'},
        {id: '4',head: '20', text: 'осіб на занятті ',  afterText: 'до'},
        {id: '5',head: '100', text: 'випускників',  afterText: 'понад'},
    ];

    return (
        <div className="infoInNumber">
            <div className="container">
                <div className="infoInNumber_wrapper">
                    {info.map((item) =>(
                        <div key={item.id} className="infoInNumber_item">
                            <p>
                            <span
                                className={`infoInNumber_item--text infoInNumber_item--text-${item.id}`}>{item.afterText}</span>
                            {item.head}
                            <span className={`infoInNumber_item--text infoInNumber_item--text-${item.id}`}>{item.text}</span></p>
                        </div>))}
                </div>
                <div className='infoInNumber_btn'><Button size={'big'}/></div>
            </div>
        </div>
    );
};

export default InfoInNumber;