import React from 'react';
import logoProOsvita from '../../assets/img/logo_pro-osvita.svg';
import iconFacebook from '../../assets/img/icon_facebook.svg';
import {useHistory} from "react-router-dom";
import iconInstagram from "../../assets/img/icon_instagram.svg";
import iconTelegram from "../../assets/img/icon_telegram.svg";

const Footer = () => {
    const history = useHistory()
    return (
        <div className="footer">
            <div className="container">
                <div className="footer_wrapper">
                    <div className="footer_content">
                        <a href='#' className="footer_logo--link footer_list--3">
                            <img src={logoProOsvita} className="footer_logo" alt="logo: ProOsvita"/></a>
                        <ul className="footer_list footer_list--1" onClick={(e) => history.push('/')}>
                            <li className="footer_list--item"><a href="#">Головна</a></li>
                            <li className="footer_list--item"><a href="#pro_osvita">Про нас</a></li>
                            <li className="footer_list--item"><a href="#program">Програма</a></li>
                            <li className="footer_list--item"><a href="#course">Участь</a></li>
                        </ul>
                        <ul className="footer_list footer_list--2" onClick={(e) => history.push('/')}>
                            <li className="footer_list--item"><a href="#study">Навчання</a></li>
                            <li className="footer_list--item"><a href="#info">Для кого</a></li>
                            <li className="footer_list--item"><a href="#teacher">Викладачі</a></li>
                            <li className="footer_list--item"><a href="#partners">Партнери</a></li>
                        </ul>
                        <ul className="footer_list footer_list--4">
                            <li className="footer_list--item">
                                <a target='_blank' className="footer_list--link"
                                   href="tel:+38 066 812 71 53">
                                    +38 066 812 71 53</a></li>
                            <li className="footer_list--item"><a target='_blank' className="footer_list--link"
                                                                 href="mailto:pab.poltava@gmail.com">pab.poltava@gmail.com</a>
                            </li>
                            <li className="footer_list--item"><a target='_blank' className="footer_list--link"
                                                                 href="https://goo.gl/maps/e8kXiHsKsYAQjrAh8">
                                м.Полтава, вул. Конституції,13</a></li>
                            <li className="footer_list--item footer_item--link">
                                <a target='_blank' href="https://www.facebook.com/asbiznesu.poltava/"
                                   title="Наш facebook">
                                    <img className="footer_link--facebook" src={iconFacebook} alt="logo: Facebook"/>
                                </a>
                                <a target='_blank' href="https://t.me/pab_poltava"
                                   title="Наш telegram">
                                    <img className="footer_link--telegram" src={iconTelegram} alt="logo: Telegram"/>
                                </a>
                                <a target='_blank' href="https://www.instagram.com/proosvita/"
                                   title="Наш instagram">
                                    <img className="footer_link--instagram" src={iconInstagram} alt="logo: Instagram"/>
                                </a>
                            </li>
                        </ul>

                    </div>
                    <hr/>
                    <p className="footer_subtitle">© Copyright 2021</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;