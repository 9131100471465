import React from 'react';
import style from "./Modal.module.sass";
import done from "../../assets/img/done_green.svg";
import Button from "../Button/Button";
import Jet from "../../assets/img/icon_jet.svg";
import Bag from "../../assets/img/bag.svg";

const Full = ({closeFunc}) => {
    return (
        <div className={style.modalWrapper} onClick={()=>closeFunc(false)}>
            <div className={style.modal} onClick={(e)=>e.stopPropagation()}>
                <div className={`${style.head} ${style.headOrange}`}>
                    <h3 className={style.header}>FULL</h3>
                    <button className={style.close} onClick={()=>closeFunc(false)}>
                        <p>+</p>
                    </button>
                </div>
                <div className={style.content}>
                    <p className={`${style.text} ${style.mb6}`}>Обирай повний пакет участі, щоб мати всі можливості
                        проєкту.<br/>
                        У новій програмі – найкрутіші заняття минулих років. Це просто топчик за відгуками наших
                        випускників 🔥</p>
                    <div className={style.df}>
                        <div className={style.firstText}>
                            <div className={`${style.df} ${style.dc}`}>
                                <p className={`${style.text} ${style.mb6}`}>
                                    <strong className={style.textStrong}>
                                        Що Буде?
                                    </strong>
                                </p>
                                <div className={style.bannerSm}>
                                    <p className={style.bannerTextSm}>Від 10 зустрічей</p>
                                </div>
                            </div>

                            <p className={`${style.text} ${style.mb6}`}>
                                з приблизною періодичністю 1 раз на тиждень.
                                Орієнтовно в другій половині дня щочетверга
                                або щоп'ятниці.
                            </p>
                            <p className={`${style.text} ${style.mb6}`}>
                                Графік буде гнучким, щоб ми з тобою могли
                                швидко реагувати на всі зміни воєнної ситуації.
                            </p>
                            <br/>
                        </div>
                        <div className={style.infoWrapper}>
                            <img className={style.infoImgT} src={Jet} alt='img: jet'/>
                            <h4 className={style.infoHeader}>У програмі:</h4>
                            <div className={`${style.info} ${style.infoSm}`}>
                                <ul className={style.infoList}>
                                    <li className={style.infoItem}>
                                        <img className={style.infoMarker} src={done} alt="icon: Done"/>
                                        <p className={style.text}>3<strong
                                            className={style.textStrong}> унікальні тренінги</strong> по 2 години</p>
                                    </li>
                                    <li className={style.infoItem}>
                                        <img className={style.infoMarker} src={done} alt="icon: Done"/>
                                        <p className={style.text}><strong className={style.textStrong}>Екскурсії на
                                            підприємства Полтави</strong></p>
                                    </li>
                                    <li className={style.infoItem}>
                                        <img className={style.infoMarker} src={done} alt="icon: Done"/>
                                        <p className={style.text}>Зустрічі<strong
                                            className={style.textStrong}> офлайн**</strong></p>
                                    </li>
                                    <li className={style.infoItem}>
                                        <img className={style.infoMarker} src={done} alt="icon: Done"/>
                                        <p className={style.text}>+ Бонус –<strong className={style.textStrong}> всі
                                            переваги пакету LIGHT</strong></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={style.main}>
                        <p className={`${style.text} ${style.mb6}`}>
                            Детальну програму ти дізнаєшся після старту проєкту. Серед тем чекай на заняття з курсів
                            PRO:економіку,
                            PRO:маркетинг та PRO:менеджмент. І тренінги на розвиток soft skills. З нашими тренерами ти
                            PROкачаєш
                            вміння самопрезентації, креативності, роботи зі своїми емоціями та планування. Даємо тобі
                            знання,
                            що допоможуть визначитися з майбутньою професією та досягти успіху в житті.
                        </p>
                        <p className={`${style.text} ${style.mb6}`}>
                            З тобою на зв'язку <strong className={style.textStrong}>професійні психолог та
                            координатор</strong>. З ними можна поговорити про свої почуття
                            і трошки зняти напругу.
                        </p>
                        <p className={`${style.text} ${style.mb6}`}>
                            А ще – виконання творчих завдань дає особливу відзнаку. Після успішного фінішу в проєкті ти
                            отримаєш
                            <strong className={style.textStrong}> доступ до закритої спільноти випускників</strong>.
                            Такі переваги лише в форматі FULL.
                        </p>
                        <p className={`${style.text} ${style.mb20}`}>
                            Витрати проєкту бере на себе Полтавська асоціація бізнесу.<br/>
                            Твій організаційний внесок <strong className={style.textStrong}>1000 грн</strong>. Тож
                            обговори це питання з батьками.
                        </p>
                    </div>
                    <p className={`${style.text} ${style.mb20}`}>
                        Чого чекаєш? Реєструйся швидше на новий набір курсу 2023 року
                    </p>
                    <img className={style.img} src={Bag} alt="img: bag"/>
                    <Button/>
                    <p className={`${style.subtext} ${style.mt20}`}>
                        * Для відвідування необхідна присутність у Полтаві
                        ** Кількість офлайн зустрічей залежатиме від воєнної ситуації
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Full;