import React, {useEffect} from 'react';
import Form from "../component/Form";
import {useHistory} from "react-router-dom";

const RegistrationPage = () => {
    const history = useHistory()
    useEffect(() => {
        history.listen(() => window.scrollTo(0, 0))
    }, [])
    return (
        <div className='formPage'>
            <Form/>
        </div>
    );
};

export default RegistrationPage;