import React from 'react';
import Label from '../../assets/img/label.png'
import avrora from '../../assets/img/partners/avrora.svg'
import yavir from '../../assets/img/partners/yavir.svg'
import konsort from '../../assets/img/partners/konsort.svg'
import digicode from '../../assets/img/partners/digicode.svg'
import greenEl from '../../assets/img/partners/green_s.svg'
import Pro from '../../assets/img/pro.svg';
import Smile from '../../assets/img/smile_orange.svg';

const Capabilities = () => {

    return (
        <section className="capabilities" id="program">
            <div className='title capabilities-title'>Робимо важливі знання доступними!</div>
            <div className='container capabilities_wrapper'>
                <h1 className='capabilities--header'>PRO:освіта 2023</h1>
                <div className='capabilities_content'>
                    <h2 className='capabilities--title capabilities--title-center'>1 об'єднана програма</h2>
                    <p className='capabilities--subtitle'>Змішаний формат – більш гнучкий</p>
                    <h2 className='capabilities--title capabilities--title-mr'>Обирай самостійно</h2>
                    <div className='capabilities_grid'>
                        <div className='capabilities_card capabilities_card--blue'>
                            <div className='capabilities_cardLeft'>
                                <p className='capabilities_cardLeft--content'>
                                    <span className='capabilities_cardLeft--text capabilities_cardLeft--text-white'>ON</span>
                                    <br/>
                                    <span className='capabilities_cardLeft--subtext capabilities_cardLeft--subtext-blue'>LINE</span>
                                </p>
                            </div>
                            <div className='capabilities_card--content'>
                                <h3 className='capabilities_card--header'>Онлайн</h3>
                                <p className='capabilities_card--text'>з будь-якої точки світу</p>
                            </div>
                        </div>
                        <div className='capabilities_card capabilities_card--pur'>
                            <div className='capabilities_cardLeft'>
                                <p className='capabilities_cardLeft--content'>
                                    <span className='capabilities_cardLeft--text capabilities_cardLeft--text-pur'>OFF</span>
                                    <br/>
                                    <span className='capabilities_cardLeft--subtext capabilities_cardLeft--subtext-white'>LINE</span>
                                </p>
                            </div>
                            <div className='capabilities_card--content'>
                                <h3 className='capabilities_card--header'>Офлайн</h3>
                                <p className='capabilities_card--text'>у м. Полтава</p>
                            </div>
                        </div>
                        <div className='capabilities_area'>
                            <div className='capabilities_oval capabilities_oval--pur'>
                                <p className='capabilities_oval--text'><img className='capabilities_oval--img' src={Pro} alt="PRO"/>фесійні</p>
                            </div>
                            <p className='capabilities_area--text'>викладачі та тренери</p>
                        </div>
                        <div className='capabilities_area capabilities_area--transform'>
                            <p className='capabilities_area--text'>Регулярне спілкування</p>
                            <div className='capabilities_oval capabilities_oval--yell'>
                                <p className='capabilities_area--text'>з психологом</p>
                            </div>
                        </div>
                    </div>
                    <div className='capabilities_list--wrapper'>
                        <h2 className='capabilities_list--header'>На тебе чекає:</h2>
                        <ul className='capabilities_list'>
                            <li className='capabilities_list--item'>особисте спілкування з підприємцями;</li>
                            <li className='capabilities_list--item'>заняття з курсів PRO:економіку, PRO:маркетинг та PRO:менеджмент;</li>
                            <li className='capabilities_list--item'>тренінги на розвиток soft skills;</li>
                            <li className='capabilities_list--item'>візити в успішні компанії Полтави;</li>
                            <li className='capabilities_list--item'>цікаві знайомства та дружня атмосфера.</li>
                        </ul>
                    </div>
                    <div className='capabilities_banner'>
                        <div className='capabilities_banner--block'>
                            <img className='capabilities_banner--img' src={Smile} alt="icon: orange smile"/>
                            <p className='capabilities_banner--text'>Початок навчання <br/> у лютому 2023 року</p>
                        </div>
                    </div>
                    <p className='capabilities_list--item capabilities_list--item-text'>Проєкт реалізується за кошти підприємців Полтавської асоціації бізнесу** </p>
                    <div className='capabilities_partners'>
                        <h4 className='capabilities_partners--header'>З нами</h4>
                        <ul className='capabilities_partners--list'>
                            <li className='capabilities_partners--item'>
                                <img className='capabilities_partners--img' src={avrora} alt="icon: avrora"/>
                            </li>
                            <li className='capabilities_partners--item'>
                                <img className='capabilities_partners--img' src={konsort} alt="icon: konsort"/>
                            </li>
                            <li className='capabilities_partners--item'>
                                <img className='capabilities_partners--img' src={digicode} alt="icon: digicode"/>
                            </li>
                            <li className='capabilities_partners--item'>
                                <img className='capabilities_partners--img' src={greenEl} alt="icon: green elephant"/>
                            </li>
                            <li className='capabilities_partners--item'>
                                <img className='capabilities_partners--img' src={yavir} alt="icon: yavir"/>
                            </li>
                            <li className='capabilities_partners--item'>
                                <a className='capabilities_partners--item-text' href="#partners">та інші</a>
                            </li>
                        </ul>
                    </div>
                    <p className='capabilities--subtext'>
                        * Програма залежить від воєнної ситуації. Від себе зробимо максимум, щоб провести усе, що запланували.
                    </p>
                    <p className='capabilities--subtext'>
                        ** Учасники сплачують організаційний внесок. Зібрані кошти ми разом використаємо для розвитку Полтави.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Capabilities;