import React from 'react';
import {Switch, Route} from 'react-router-dom'
import HomePage from "./Page/HomePage";
import RegistrationPage from "./Page/RegistrationPage";
import AdminPage from "./Page/AdminPage";
import Auth from "./component/Admin/Auth";

const Routes = () => (
        <Switch>
            <Route path="/" exact component={HomePage}/>
            <Route path="/form" component={RegistrationPage}/>
            <Route path="/admin" component={Auth}/>
            <Route path="/adminData" component={AdminPage}/>
            <Route path="/#course" component={HomePage}/>
        </Switch>
);
export default Routes;