import React from 'react';
import img1 from '../../assets/img/security/img_1.svg';
import img2 from '../../assets/img/security/img_2.svg';
import img3 from '../../assets/img/security/img_3.svg';
import img4 from '../../assets/img/security/img_4.svg';
import img5 from '../../assets/img/security/img_5.svg';

const Security = () => {
  const protectionList = [
    {img: img1, text: 'Онлайн з будь-якої точки світу'},
    {img: img4, text: 'Офлайн у місті Полтава*'},
    {img: img5, text: 'Дотримання правил безпеки під час воєнного стану** '},
    {img: img2, text: 'Обмежена кількість учасників'},
    {img: img3, text: 'Засоби індивідуального захисту'}];
  return (
    <div className="security">
      <div className="container">
        <div className="security_wrapper">
          <h1 className="security_header">ЗМІШАНИЙ ФОРМАТ для твого комфорту та безпеки</h1>
          <div className="security_list">
            {protectionList.map((item) =>
              <div key={item.text} className="security_list--item">
                <img src={item.img} alt="icon: protection" className="security_list--item-img"/>
                <p className="security_list--item-text">{item.text}</p>
              </div>)}
          </div>
          <p className="security_list--item-subtext">* Місце проведення обов'язково матиме доступне укриття. Куди переходять усі учасники у випадку повітряної тривоги.</p>
          <p className="security_list--item-subtext">** Для участі обов'язкова згода одного з батьків</p>
        </div>
      </div>
    </div>
  );
};

export default Security;