import React, {useEffect, useState} from 'react';
import Button from "../../UI/Button/Button";
import logoProOsvita from '../../assets/img/logo_pro-osvita(purple).svg'
import logoPab from '../../assets/img/logo_pab.svg'
import iconLocation from '../../assets/img/icon_location.svg'
import logoMarketing from '../../assets/img/logo_marketing.svg'
import logoManagement from '../../assets/img/logo_management.svg'
import logoEconomy from '../../assets/img/logo_economy.svg'
import iconSmile from '../../assets/img/smile_orange.svg'

const HeaderInfo = () => {
    const arrSubtitle = [{first: 'У нас', green: ' PROгресивні знання', two: ', яких не вивчають у школі'}, {first: 'Говоримо ', green: ' PROстими словами ', two: 'про бізнес'},{first: 'Даємо', green: ' важливі скіли ', two: '21-го століття'}, {first: 'PROкачуємо', green: ' важливі навички', two: ' для успішного життя'}];
    const [subtitle, setSubtitle] = useState(arrSubtitle[0]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setSubtitle(arrSubtitle[count]);
            setCount(count + 1);
        }, 2000);

        if (count > 3) {
            setCount(0);
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        }

    }, [count]);

    return (
        <header className='header'>
            <div className='container header_container'>
                <div className='header_wrapper'>
                    <div className='header_text'>
                        <img className='header_text--logo' src={logoProOsvita}/>
                        <h1 className='header_text--subtitle'>{subtitle.first}<span className='header_text--subtitle-green'>{subtitle.green}</span>{subtitle.two}</h1>
                        <div className='header_text--button'><Button size={'Big'}/></div>
                    </div>
                    <div className='header_info'>
                        <div className='header_info--pab'>
                            <img className='header_info--logoPab' src={logoPab}/>
                            <div className='header_info--location'>
                                <img src={iconLocation} alt='icon: Location' className='header_info--icon'/>
                                <span className={'header_info--city'}>Полтава</span>
                            </div>
                        </div>
                        <div className='header_info--button'><Button size={'Big'}/></div>
                    </div>
                </div>
            </div>
            <h1 className='header_subtitle'> Навчання розпочалося у лютому 2023 року </h1>
        </header>
    );
};

export default HeaderInfo;