import React from 'react';
import Timer from "../../UI/Timer/Timer";
import HeaderInfo from "./HeaderInfo";

const Header = () => {
    return (
        <div className='head'>
            <HeaderInfo/>
            {/*<Timer/>*/}
        </div>
    );
};

export default Header;