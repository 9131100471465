import React from 'react';
import rocketIcon from '../../assets/img/thunder.svg'
import Button from "../../UI/Button/Button";

const Learn = () => {
    const infoPointOne = [
        {
            id: '22',
            name: 'тобі від 14 до 18 років;'
        },
        {
            id: '23',
            name: 'ти любиш навчатися і здобувати нові знання;'
        },
        {
            id: '24',
            name: 'хочеш зрозуміти свої почуття під час занять із психологами;'
        },
        {
            id: '25',
            name: 'цікавишся, як працює бізнес зсередини;'
        },
        {
            id: '26',
            name: 'бажаєш правильно розпоряджатися своїми грошима та планувати бюджет;'
        },
        {
            id: '345',
            name: 'мрієш мати навички, які приведуть тебе до успіху в житті.'
        },
    ]

    const infoPointTwo = [
        {
            id: '28',
            name: 'спробуєш себе в ролі економіста, маркетолога чи менеджера;'
        },
        {
            id: '29',
            name: 'познайомишся з успішними підприємцями;'
        },
        {
            id: '30',
            name: 'дізнаєшся, як запускають стартапи;'
        },
        {
            id: '31',
            name: 'почуєш від PROфесійних спікерів про самопрезентацію та самомотивацію;'
        },
        {
            id: '32',
            name: 'розвиватимеш свою креативність;'
        },
        {
            id: '32d',
            name: 'проведеш час у приємній атмосфері та зможеш знайти собі нових друзів.'
        },
    ]
    return (
        <section className="conditions" id="info">
            <h3 className="conditions_title">Для кого?</h3>
            <div className='container'>
                <div className="block">
                    <div className="block-text block-text--1">
                        <ul>
                            <h2 className="block-text__head">Ми шукаємо саме тебе, якщо ти:</h2>
                            {infoPointOne.map(item => (
                                <li key={item.id} className="block-text__item">
                                    <img src={rocketIcon} alt="rocket" className="block-text__img"/>
                                    <span className="block-text__point">{item.name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="block-text block-text--2">
                        <ul>
                            <h2 className="block-text__head">Під час проекту ти:</h2>
                            {infoPointTwo.map(item => (
                                <li className="block-text__item" key={item.id}>
                                    <img src={rocketIcon} alt="rocket" className="block-text__img"/>
                                    <span
                                        className="block-text__point">{item.name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='learn_btn'><Button size={'Big'}/></div>
            </div>
        </section>
    );
};

export default Learn;