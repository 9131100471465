import React from 'react';
import logo_Pro from '../../assets/img/logo_pro-osvita.svg'
import imgStudy from '../../assets/img/img_info.png'
import imgPab from '../../assets/img/imgPab.png'
import iconMap from '../../assets/img/icon_map.svg'

const ProosvitaInfo = () => {
    return (
        <section className="home" id='pro_osvita'>
            <div className='container'>
                <div className="cards">
                    <div className="card card--1">
                        <div className="card__text">
                            <div className="card__headLogo">
                                <img src={logo_Pro} alt="logo: PROosvita"/>
                            </div>
                            <div className='card_content'>
                                – це освітній проєкт для молоді від 14 до 18 років.<br/>
                                <strong className="card__text--2">PRO</strong>
                                фесійний підхід до викладання та <br/>
                                <strong className="card__text--3">PRO</strong>
                                гресивні знання, яких не вивчають у школі.
                                <p className="card__text card__text--p20px">
                                    З 2023 року – змішаний формат
                                </p>
                                <div className='card__text--location'>
                                    <img alt='icon: Map' src={iconMap} className='card__text--location-img'/>
                                    <p  className='card__text--location-text'>Заняття офлайн у м. Полтава та частково онлайн</p>
                                </div>
                            </div>
                            <p className="card__text--p20px">
                                Будь успішним та прогресивним разом з нами!
                            </p>
                        </div>
                    </div>
                    <div className="card card--2">
                        <img
                            src={imgStudy}
                            alt="img: Photo"
                            className="card__img"
                        />
                    </div>
                    <div className="card card--3">
                        <img
                            src={imgPab}
                            alt="img: PAB"
                            className="card__img card__img--pab"
                        />
                    </div>
                    <div className="card card--4">
                        <div className="card__text">
                            <div className='card_content card_content--2'>
                                Організатором освітнього проекту
                                <strong className="card__text--2"> “PRO:освіта” </strong> є Полтавська
                                асоціація бізнесу.<br/><br/>
                                <strong className="card__text--3">ПАБ</strong> – це об’єднання успішних полтавських
                                підприємців.
                                <br/><br/>
                                Люди, які зробили свій бізнес розвиненим, переконані, що навчання є фундаментом цього
                                успіху.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
        ;
};

export default ProosvitaInfo;