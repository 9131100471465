import React, {useState} from 'react';
import logoProOsvita from '../../assets/img/logo_pro-osvita.svg'
import logoProOsvitaPurple from '../../assets/img/logo_pro-osvita(purple).svg'
import iconFacebook from '../../assets/img/icon_facebook.svg'
import iconInstagram from '../../assets/img/icon_instagram.svg'
import Button from "../../UI/Button/Button";
import {useHistory} from 'react-router-dom'
import Menu from "./Menu";
import menuIcon from '../../assets/img/menu.svg'

const Navbar = () => {
    const [menu, setMenu] = useState(false)
    const history = useHistory()
    const tabs = [
        {
            id: 'a1',
            name: 'Про нас',
            link: '#pro_osvita'
        },
        {
            id: 'a3',
            name: 'Програма',
            link: '#program'
        },
        {
            id: 'a4',
            name: 'Участь',
            link: '#course'
        },
        {
            id: 'a5',
            name: 'Викладачі',
            link: '#teacher'
        },
        {
            id: 'a6',
            name: 'Відгуки',
            link: '#'
        }
        ];

    return (
        <div className='navbar'>
            <nav className="container">
                <ul className="navbar_listItem">
                    <li className="navbar_item navbar_item--logo">
                        <a className="navbar_link" href="#" title="Головна">
                            <img className="navbar_link--logo" src={logoProOsvita} alt="logo: ProOsvita"  onClick={() => history.push('/')}/>
                            <img className="navbar_link--logo_purple" src={logoProOsvitaPurple} alt="logo: ProOsvita"  onClick={() => history.push('/')}/>
                        </a>
                    </li >
                    {tabs.map((item) => (
                        <li className="navbar_item navbar_item--menu" key={item.id}>
                            <a className="navbar_link" href={item.link}
                               onClick={() => history.push('/')}>{item.name}</a>
                        </li>
                    ))}
                    <li className="navbar_item navbar_item--link">
                        <a target='_blank' href="https://www.instagram.com/proosvita/" className="navbar_link" title="Наш instagram">
                            <img className="navbar_link--instagram" src={iconInstagram} alt="logo: Instagram" />
                        </a>
                        <a target='_blank' href="https://www.facebook.com/asbiznesu.poltava/" className="navbar_link" title="Наш facebook">
                            <img className="navbar_link--facebook" src={iconFacebook} alt="logo: Facebook"/>
                        </a>
                    </li>
                    <li className="navbar_item navbar_item--button">
                        <Button/>
                    </li>
                    <li className='burgerMenu' onClick={() => {
                        setMenu(!menu)
                    }}>
                        <img className='burgerIcon' src={menuIcon} alt='menu'/>
                        {menu ? <Menu tabs={tabs}/> : ''}
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Navbar;