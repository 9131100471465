import React, {useState} from 'react';
import Button from "../../UI/Button/Button";
import bag from '../../assets/img/bag.svg';
import smileOrange from '../../assets/img/smile_orange.svg';
import smileBlue from '../../assets/img/smile_blue.svg';
import done from '../../assets/img/done_green.svg'
import Beginner from "../../UI/ModalInfo/Beginner";
import Light from "../../UI/ModalInfo/Light";
import Full from "../../UI/ModalInfo/Full";

const Course = () => {

    const [beginner, setBeginner] = useState(false)
    const [light, setLight] = useState(false)
    const [full, setFull] = useState(false)

    const arrCourse = [
        {
            id: 'beginner',
            img: bag,
            cls: '',
            color: 'green',
            name: 'BEGINNER',
            text: 'Ідеально для тих, хто хоче відчути, як це бути серед наших PRO:освітян. Обирай будь-яке заняття на вибір.',
            textList: [],
            banner: '',
            bannerImg: '',
            point: [
                'Це може бути онлайн або офлайн зустріч'
            ],
            price: '150',
            toggleFunc: setBeginner
        },
        {
            id: 'light',
            img: '',
            cls: '',
            color: 'blue',
            name: 'LIGHT',
            text: 'Долучайся з будь-якої точки світу. Тобі потрібні лише світло, інтернет… а головне – бажання мати PROгресивні знання.',
            textList: [],
            banner: 'Для тебе',
            bannerImg: smileOrange,
            point: [
                '3 онлайн-заняття по 2 години',
                'Доступ до відеозаписів',
                'Гнучкий графік',
                'Спілкування з тренерами та іншими учасниками'
            ],
            price: '300',
            toggleFunc: setLight
        },
        {
            id: 'full',
            img: '',
            cls: '',
            color: 'orange',
            name: 'FULL',
            text: `Ти зараз у Полтаві?                  Давай зустрічатися особисто. Отримай усі можливості проєкту.`,
            textList: [
                'Це 10+ зустрічей', 'Гнучкий графік'
            ],
            banner: 'Заплановані',
            bannerImg: smileBlue,
            point: [
                'Екскурсії на підприємства',
                'Офлайн-тренінги',
                'Творчі завдання'
            ],
            price: '1000',
            toggleFunc: setFull
        },
    ]

    const ButtonInfo = ({func}) => (
        <button className='course_card-btn--info' onClick={() => {
            func(true)
        }}>Дізнатися більше< /button>
    )

    return (
        <section className="course" id="course">
            <div className="container">
                <h1 className="course--header">Варіанти участі</h1>
                <div className="course-wrapper">
                    {arrCourse.map(item => (
                        <div key={item.color} id={item.id} className={`course_card course_card--${item.color}`}>
                            <div className='course_card-head'>
                                <h3 className='course_card-head--text'>{item.name}</h3>
                            </div>
                            <div className='course_card-content'>
                                <div className='course_top'>
                                    {
                                        item.text ? <p className='course_card--text'>{item.text}</p> : ""
                                    }
                                    {
                                        item.textList.length !== 0 ?
                                            <ul className='course_card-textList'>{item.textList.map(point => <li
                                                className='course_card-textList--item' key={point}>{point}</li>)}</ul> : ''
                                    }
                                    {
                                        item.banner ?
                                            <div className='course_card-banner--wrapper'>
                                                <div
                                                    className={`course_card-banner course_card-banner--${item.color}`}>{item.bannerImg ?
                                                    <img
                                                        className={`course_card-banner--img course_card-banner--img--${item.color}`}
                                                        src={item.bannerImg}
                                                        alt={item.name}/> : ''}
                                                    <p className='course_card-banner--text'>{item.banner}</p>
                                                </div>
                                            </div> : ''
                                    }
                                    {
                                        item.point.length !== 0 ?
                                            <ul className='course_card-point'>
                                                {
                                                    item.point.map(text => <li
                                                        className='course_card-point--item' key={text}>
                                                        <img className='course_card-point--img' src={done}
                                                             alt='icon: green done'/>
                                                        {text}</li>)
                                                }
                                            </ul>
                                            : ''
                                    }
                                    {
                                        item.img ?
                                            <img className='course_card-img' src={item.img} alt={item.name}/> : ''
                                    }
                                </div>
                                <div className='course_footer'>
                                    <ButtonInfo func={item.toggleFunc}/>
                                    <div className='course_card-price'>
                                        <strong className='course_card-price--text'>{item.price}<sup
                                            className='course_card-price--subtext'>грн</sup></strong>
                                    </div>
                                </div>
                            </div>
                            <div className='course_card-btn'><Button size={'big'}/></div>
                        </div>
                    ))}


                    {beginner && <Beginner closeFunc={setBeginner}/>}

                    {light && <Light closeFunc={setLight}/>}

                    {full && <Full closeFunc={setFull}/>}
                </div>
            </div>
        </section>
    );
};

export default Course;