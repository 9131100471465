import React from 'react';
import style from './Button.module.sass'
import {NavLink} from "react-router-dom";

const Button = ({size = ''}) => {
    return (
        <NavLink to={'form'} onClick={()=>window.scrollTo(0, 0)}>
            <button className={(`${style.btn} ${size === '' ? style.button : style.buttonBig}`)}>
                Реєстрація
            </button>
        </NavLink>

    );
};

export default Button;