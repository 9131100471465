import React from 'react';
import {NavLink} from "react-router-dom";

const FinishForm = () => {
    return (
        <div className='finishForm'>
            <h1 className='form_header'>Дякуємо!<br/> Твою заявку на участь прийнято 👌</h1>
            <p className='form_text mt-20'>
                Очікуй на пошті листа протягом 5 найближчих днів. Наш координатор напише тобі щодо підтвердження
                реєстрації. Раптом не отримаєш, телефонуй +38 066 812 71 53
                <br/><br/>
                Деталі щодо участі надішлемо тобі після завершення набору 😊
            </p>
            <NavLink to={'/'}>
                <button className='form_button mt-20'>На головну</button>
            </NavLink>
        </div>
    );
};

export default FinishForm;