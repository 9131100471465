import firebase from "firebase/app";

export const setDB = async (data) => {
    try {
        const querySnapshot = firebase.firestore().collection('2023').doc();
        await querySnapshot.set({ ...data});
        return querySnapshot;
    } catch (e) {
        console.log(e);
    }
};

export const getDB = async (url) => {
    try {
        const adminsData = await firebase.firestore().collection(url).get();
        return adminsData.docs;
    } catch (e) {
        console.log(e);
    }
};

export const setDBDoc = async ( doc, data) => {
    try {
        const querySnapshot = firebase.firestore().collection('2023').doc(doc);
        await querySnapshot.set({ ...data, id: querySnapshot.id });
        return querySnapshot;
    } catch (e) {
        console.log(e);
    }
};

export const authEmailAndPassword = async (
    {email,
    password}
) => {
    try {
        await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (e) {
        console.log(e);
    }
};

export const logOut = async () => {
    try {
        await firebase.auth().signOut();
    } catch (e) {
        console.log(e);
    }
};
