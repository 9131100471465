import React, {useContext, useState} from 'react';
import {FirebaseContext} from "../../App";
import "firebase/auth";
import {Redirect} from "react-router-dom";
import {authEmailAndPassword} from "../../api";

const Auth = () => {
    const [data, setData] = useState({email: '', password: ''})
    const { isAdmin} = useContext(FirebaseContext);
    if (isAdmin) {
        return <Redirect to={'/adminData'}/>;
    }

    const authUser = async (e) => {
        e.preventDefault();
        try {
            await authEmailAndPassword({...data});
        } catch (e) {
            console.log(e)
        }
    };

    return (
        <div className="auth">
            <div className="auth_wrapper">
                <h1 className="auth_header">Авторизація</h1>
                <form className="auth_form">
                    <input className='form_input' placeholder='Email' value={data.email}
                           onChange={(e) => setData({...data, email: e.target.value})}/>
                    <input className='form_input' placeholder='Password' value={data.password}
                           onChange={(e) => setData({...data, password: e.target.value})}/>
                    <button className='auth_button' type="submit"
                            onClick={authUser} >Вхід</button>
                </form>
            </div>
        </div>
    );
};

export default Auth;